
    <div class="bigwrap">
      <div class="contentleftmargin">
        <article id="closed">
          <p style="text-indent: 0;">Dear Reader,</p>

          <p>Ebbflow has closed down. The business was not sustainable and thus the service was turned off. It was a difficult decision. Ebbflow started as a proof-of-concept idea and blossomed into a fully operational startup in a very short time - 10 months. I learned a lot through creating Ebbflow. As a solo venture, I had to make every decision and get past every roadblock myself. The annoying tasks wouldn't go away with inaction, only delivery and constant effort could drive the project forward. I learned the skill of decision-making in the name of delivery which requires choosing between long-term benefits and short-term yields. I additionally felt a great sense of freedom, writing over 20k lines of code in 10 months. It was a great experience.</p>
          <p>If you are reading this, maybe you are interested in the problem space of simplifying networking or are just looking for the blog posts that might help you deliver Rust projects. Nonetheless, thanks for your time. If you have any questions about how Ebbflow worked or what functionality it provided, feel free to reach out (contact info below)!</p>
          <p>- Ryan G</p>
        </article>
        <!-- {{&gt; flash this}} -->
        <div id="maintitle">
          <div style="position: relative;">
            <div id="titletitle">
              <div>
                Your
              </div>
              <div class="carousel">
                <div class="change_outer">
                  <div class="change_inner">
                    <div class="element">
                      <img class="cib" src="/assets/cloudlogos3.png" alt="cloudlogos3">Any-Cloud
                    </div>
                    <div class="element">
                      <img class="ci" src="/assets/map.svg" alt="map">Worldwide
                    </div>
                    <div class="element">
                      <img class="ci" src="/assets/k8s.png" alt="k8s">Kubernetes
                    </div>
                    <div class="element">
                      <img class="ci" src="/assets/shuffle.svg" alt="shuffle">Multi-Cloud
                    </div>
                    <div class="element">
                      <img class="ci" src="/assets/map-pin.svg" alt="map-pin">On Premises
                    </div>
                    <div class="element">
                      <img class="ci" src="/assets/rpi.png" alt="rpi">RaspberryPi
                    </div>
                  </div>
                </div>
              </div>
              <div style="flex-shrink: 0;">
                Load Balancer
              </div>
            </div>
            <div id="sshproxycircle">
              <strong>+ SSH Proxy!</strong>
            </div>
          </div>
          <p class="subp">Host websites from home, on-premises, Raspberry Pis, AWS, Azure, GCP, or anywhere else you can imagine, all at the same time using Ebbflow's global multi-cloud load balancing services. Also SSH to them from any network with Ebbflow's built-in SSH Proxy service. Ebbflow's resilient multi-region, multi-cloud platform ensures industry-leading availability.</p>
          <!-- <p class="subp" id="infact">In fact, this site is hosted through Ebbflow and this request was served by <code id="hostname">{{hostname}}</code></p> -->
        </div>
        <div class="fpzone boxright">
          <img id="mainimg" class="fpimg fpzoneimg" src="/assets/ebbmain.png" alt="ebbmain">
          <div class="fpzoneinner">
            <div class="fptextblox">
              <h3>Unlimited Endpoints</h3>
              <p>You don't pay per load balancer ("endpoint") or browser trusted TLS certificate - you can provision unlimited endpoints and serve traffic from unlimited servers and you only pay for data transfer.</p>
            </div>
            <div class="fptextblox">
              <h3>Multi Cloud; Any Cloud; No Cloud</h3>
              <p>Ebbflow will route users to your capacity in any cloud provider or on premises location. Also, Ebbflow itself is deployed to multiple cloud providers for reliability and flexibility.</p>
            </div>
            <div class="fptextblox">
              <h3>Global, Nearest-Server Routing</h3>
              <p>Not regional, not zonal, but a single global frontend for you and your users. Ebbflow will route users to the nearest servers automatically.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
