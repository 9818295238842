<head>
    <title>ebbflow | Linux Packages For Rust (2/2) - Building with GitHub Actions</title>
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Ebbflow: GitHub Actions for Automated .deb & .rpm Builds for Rust Projects" />
    <meta name="twitter:description" content="Learn how Ebbflow uses GitHub Actions for building its client packages for various Linux distros"/>
    <meta name="twitter:image" content="https://ebbflow.io/assets/vl2.png" /> 
</head>

<div class="contentleftmargin">
    <div class="pod podtop">
        <article class="blogarticle">
        <div style="margin: auto;max-width: 800px;text-align: center; margin-top: 60px;">
        <a href="/blog/vending-linux-2"><h2 style="margin-bottom: 16px">Linux Packages For Rust (2/2) - Building with GitHub Actions using Custom Actions and Docker Container Images</h2></a><h3 style="margin-top: 16px">A look into vending a Rust project for various OSes and CPU architectures.</h3>
        <div class="blogtext" style="display: flex; flex-direction: unset"><small><i><a href="/blog/vending-linux-1"><< Part 1 - Building the <code>.deb</code> and <code>.rpm</code></a></i></small></div>
            <p style="text-align: center;">Ryan Gorup - Founder</p>
            </div>
            <div class="blogtext">
                <img class="blogicon" style="max-width: 40%;" src="/assets/vl2.png" />
                <p>This post describes how Ebbflow vends its <a href="https://github.com/ebbflow-io/ebbflow">client</a> which is written in Rust to its Linux users, describing the tools used to build the various packages for popular distributions. This guide starts off assuming we can build <code>.deb</code> and <code>.rpm</code> packages locally which we covered in our <a href="/blog/vending-linux-1">previous blog post</a>, but now we want to move towards distribution. Specifically, we will discuss how the client is built for all of its target platforms using GitHub Actions. The Docker images and GitHub Actions that we discuss are all public and usable by anyone. Here's what we have in this post:</p>
                    <table style="margin: 30px 0px;">
                        <tr>
                            <td>0.0</td>
                            <td><a href="/blog/vending-linux-2#autobuilds">Motivation & Intro to GitHub Actions</a></td>
                        </tr>
                        <tr>
                            <td>0.1</td>
                            <td><a href="/blog/vending-linux-2#plan">The Plan: Docker Images > Actions > Workflow</a></td>
                        </tr>
                        <tr>
                            <td>1.0</td>
                            <td><a href="/blog/vending-linux-2#docker">Docker Images</a></td>
                        </tr>
                        <tr>
                            <td>1.1</td>
                            <td><a href="/blog/vending-linux-2#magic">&nbsp GitHub Actions' Container Magic</a></td>
                        </tr>
                        <tr>
                            <td>1.2</td>
                            <td><a href="/blog/vending-linux-2#imgs">&nbsp Our Build Images</a></td>
                        </tr>
                        <tr>
                            <td>1.3</td>
                            <td><a href="/blog/vending-linux-2#imagebuild">&nbsp Building & Hosting the Build Images</a></td>
                        </tr>
                        <tr>
                            <td>2.0</td>
                            <td><a href="/blog/vending-linux-2#vendedactions">Creating GitHub Actions</a></td>
                        </tr>
                        <tr>
                            <td>3.0</td>
                            <td><a href="/blog/vending-linux-2#workflow">Final GitHub Action Workflow</a></td>
                        </tr>
                        <tr>
                            <td>4.0</td>
                            <td><a href="/blog/vending-linux-2#wrapup">Wrapup: All of our Public Resources</a></td>
                        </tr>
                    </table>
                    <p>In our next blog post of this series we will talk about the final step of getting the package into the hands of our users by detailing our package server release process.</p>
                <h3>Ebbflow Client 101</h3>
                <p>Ebbflow's job is to route user traffic to your web server application (or SSH Daemon) on your servers. The central Ebbflow service proxies data between user connections (e.g. browser, SSH client) and the client. The client then proxies the data to your web server or local SSH daemon. Users of Ebbflow install the client on machines that will host endpoints, be SSH-ed to, or both at the same time (which is very useful). You can find the client's code <a href="https://github.com/ebbflow-io/ebbflow">on GitHub</a>. We talked about taking the client's binaries and packaging them into <code>.deb</code> and <code>.rpm</code> packages in the <a href="/blog/vending-linux-1">previous blog post</a>. The client is also vended to <a href="/blog/vending-win">Windows users</a>.</p>

                <h2 id="autobuilds"><a href="/blog/vending-linux-2#autobuilds"><code>0.0</code> Motivation: Automated Builds</a></h2>
                <div style="display: flex; flex-wrap: wrap;">
                <img class="blogicon" src="/assets/bigpackage.svg" />
                <img class="blogicon" src="/assets/bigpackage.svg" />
                <img class="blogicon" src="/assets/bigpackage.svg" />
                <img class="blogicon" src="/assets/bigpackage.svg" />
                <img class="blogicon" src="/assets/bigpackage.svg" />
                </div>
                <p>It's one thing to have your code written and to be able to build it on your development machine, it's another thing to build the project for multiple OSs or CPU architectures in any sane way. I could harp on this for a long time, but I'll spare you the trouble and continue to tell you how the Ebbflow client is built for the numerous target platforms.
                </p>
                <p>There are many methods to build your code. Typically you will hook up some build service so that when you <code>git push</code> or otherwise merge in code, a build will automatically kick off and spit out the resulting artifacts. As far as build services go, <a href="https://travis-ci.org/">Travis CI</a> is popular, there's <a href="https://www.jenkins.io/">Jenkins</a>, <a href="https://aws.amazon.com/codebuild/">AWS CodeBuild</a>, <a href="https://azure.microsoft.com/en-us/services/devops/pipelines/">Azure Pipelines</a>, and many others. GitHub, where the Ebbflow client's <a href="https://github.com/ebbflow-io/ebbflow">source code</a> is hosted, launched <a href="https://github.com/features/actions">GitHub Actions</a>, another continuous integration and building platform.</p>
                <h3 id="ghactions"><a href="/blog/vending-linux-2#ghactions">GitHub Actions for Automated Builds</a></h3>
                <img class="blogicon" src="/assets/ghactions.png" />

                <p>GitHub Actions has two important things going for it. First, the code is hosted through GitHub so integration is a piece of cake. Secondly, they have a <a href="https://github.com/marketplace?type=actions">marketplace</a> for using Action definitions built by other people in your workflow This marketplace makes it super easy to use and you can begin building in minutes.</p>

                <p>The build process we are going to discuss can get confusing especially if you've never worked with distributed build platforms, Docker, or GitHub Actions before. GitHub Action executions start at its highest organizational unit, the Workflow:</p>
                <ul>
                    <li>Workflows run Jobs</li>
                    <li>Jobs execute on Runners which are virtual build environments<ul>
                        <li>Runners are provided by GitHub and run either Ubuntu, macOS, or Windows</li></ul></li>
                    <li>Jobs have Steps, which can use Marketplace Actions or just do simple things like execute individual shell commands</li>
                    <li>Steps run on the Runner image, or can run in custom Docker containers (!!)</li>
                </ul>
                <p>Keep in mind that the word <i>Action</i> is overloaded or is confusingly used in general. The entire build framework/product/service is named GitHub Actions. But, inside of your Workflow you specify other 'Actions' that can be from the marketplace. And when you use an Action in your Workflow, it's really just a Step of that Workflow, and the word 'Action' doesn't really get used in your Workflow. It's a little sticky!</p>

            <details><summary><i>GitHub Actions QuickStart if you want to try it yourself!</i></summary>
            <p><small>To get started with GitHub Actions & Rust code click the 'Actions' tab in your GitHub repo and start with some simple workflows, likely language specific ones like Rust build environment GitHub provides 1st-party. For more advanced Rust build settings, check out <a href="https://github.com/marketplace/actions/rust-cargo">rust-cargo</a> for using any <code>cargo</code> command and <a href="https://github.com/marketplace/actions/rust-clippy-check">rust-clippy-check</a> to run <a href="https://github.com/rust-lang/rust-clippy">Clippy</a>, both created by the <a href="https://actions-rs.github.io/">actions-rs</a> group.</small></p>
        </details>
                <p>Now, if you remember from our <a href="/blog/vending-linux-1">last blog post</a>, we build our Rust code into proper Linux packages namely <code>.deb</code> and <code>.rpm</code> formats. To do this, we use the <code>cargo deb</code> and <code>cargo rpm</code> commands provided through the <a href="https://crates.io/crates/cargo-deb">cargo-deb</a> and <a href="https://crates.io/crates/cargo-rpm">cargo-rpm</a> crates respectively. We could simply install these commands onto the Runner that GitHub provides using the <a href="https://github.com/marketplace/actions/rust-cargo-install">rust-cargo-install</a> action. Sounds great!</p>
                <p>One problem: cargo-deb and cargo-rpm require OS installed tools such as <code>dpkg</code>, <code>ldd</code>, or <code>rpm-build</code>. Also we are <a href="/blog/vending-linux-1#static">statically linking</a> <code>MUSL</code> which also needs to be installed to the OS. GitHub provides a lot of <a href="https://docs.github.com/en/actions/reference/software-installed-on-github-hosted-runners">software pre-installed</a> but the GitHub runner for Linux runs Ubuntu and installing <code>rpm-build</code> to Ubuntu is possible, but not simple.</p>
                <p>Instead of dealing with GitHub's runners, we could take advantage of what we described earlier: the ability to run a step on a custom Docker container. This will allow us to create images that have everything set up exactly how we need them, and we can build directly on a given distro that we are targeting. Let's look at how we created those images.</p>

                <h2 id="plan"><a href="/blog/vending-linux-2#plan"><code>0.1</code> The Plan: Docker Images > Actions > Workflow</a></h2>
                <p>Our goal is that when we <code>git push</code> new client code, GitHub Actions will start our workflow which will execute certain build steps in our custom Docker images. To make this happen, we will create Docker images that have all of the OS packages we need, then reference these images in an Action, then reference that Action in our Workflow. We will discuss these topics from the ground up.</p>

                <h2 id="docker"><a href="/blog/vending-linux-2#docker"><code>1.0</code> Developing the Docker Images</a></h2>
                <p>GitHub Actions allows you to bring-your-own container in a feature named <a href="https://docs.github.com/en/actions/creating-actions/creating-a-docker-container-action">Container Actions</a> where your build will executed inside of a provided Docker image, one that we will install Rust and all of the OS packages we need. Its pretty fantastic actually, you just adhere to a <a href="https://docs.github.com/en/actions/creating-actions/dockerfile-support-for-github-actions">small spec for your Dockerfile</a> and then your GitHub Action definition kind of just will run inside the container when you want it to.</p>

            <p>The general process for developing these images and creating a container which can build our packages with all of the necessary dependencies is to just start with a base image for a given distro such as Debian or Fedora and from there install Rust. I would run a <code>cargo build</code> in this container to make sure my code can work, before worrying about the <code>.deb</code> or <code>.rpm</code> packages. I then install <code>MUSL</code> and build a statically linked target to make sure that works. Finally I would install <code>cargo deb</code> or <code>cargo rpm</code>, then execute that and fix any dependencies until your build succeeds. Tip: Don't squash your image into one small layer until you are done or your builds will take a long time during development. Credit is owed to the <a href="https://github.com/clux/muslrust">muslrust</a> project by Github user <a href="https://github.com/clux">clux</a> which inspired (or is the base of) all of the images we built.</p>

                This process will give us an image we could use to build our project locally, but we will need to hook these up with GitHub. Let's look at how GitHub uses our Docker images real quick.
                <h2 id="magic"><a href="/blog/vending-linux-2#magic"><code>1.1</code> GitHub Actions' Container Magic</a></h2>
                <img class="blogicon" src="/assets/githubcute.svg" />
                <p>The container integration with GitHub actions is pretty interesting when you think about it. They have a Runner running Linux (or macOS or Windows) and your steps run directly on this, but some of your step can run inside custom containers alongside your previous or subsequent commands. You could have a single Job use multiple containers and execute commands in each of them, and copy files between all of them. Your containers can see your source code that you likely copied as the first step of the Job. Note that the source code was copied onto the GitHub Runner before your image was pulled!</p>
                <p>The secret sauce is explained in the <a href="https://docs.github.com/en/actions/creating-actions/dockerfile-support-for-github-actions">Dockerfile guide</a> that you read when creating the Docker images. GitHub accomplishes this via a mount of the working directory that has been used so far onto your container! This mount does the trick to give your container access to files that were from the GitHub hosted Runner and vice versa. Pretty simple, pretty neat!</p>
                <h2 id="imgs"><a href="/blog/vending-linux-2#imgs"><code>1.2</code> Our Build Images</a></h2>
                <p>Here are the various packages and architecture targets and links to their section. All of these images are open source and available on GitHub, <a href="https://hub.docker.com/u/ebbflow/">DockerHub</a>, and also usable through the GitHub Actions we discuss later.</p>
                <table>
                    <tr><td><code>.deb</code></td><td>amd64</td><td><a href="/blog/vending-linux-2#debamdubuntu">Ubuntu & Debian</a></td></tr>
                    <tr><td><code>.deb</code></td><td>armv7</td><td><a href="/blog/vending-linux-2#raspbian">Raspbian (or RaspberryPi OS)</a></td></tr>
                    <tr><td><code>.rpm</code></td><td>amd64</td><td><a href="/blog/vending-linux-2#fedora">Fedora</a></td></tr>
                    <tr><td><code>.rpm</code></td><td>amd64</td><td><a href="/blog/vending-linux-2#opensuse">OpenSUSE</a></td></tr>
                </table>
            
                <h4 id="debamdubuntu"><a href="/blog/vending-linux-2#debamdubuntu"><code>.deb</code> for <code>amd64</code> Ubuntu & Debian</a></h4>
                <p>Poking around the internet when starting this process I saw a docker image for building statically-linked Rust projects named <a href="https://github.com/clux/muslrust">muslrust</a> by Github user <a href="https://github.com/clux">clux</a>. This image provided everything we needed for our images, except that it doesn't have the <code>cargo deb</code> command installed. So, I simply created a new Docker image from this image and added the <code>cargo deb</code> installation. Full credit goes to the muslrust project which inspired all of our other images which we discuss below. Here is our <code>Dockerfile</code> for the <code>amd64</code>/<code>.deb</code> build image in its entirety:</p>
<pre><code>FROM clux/muslrust

RUN cargo install cargo-deb

COPY entrypoint.sh /entrypoint.sh
ENTRYPOINT ["/entrypoint.sh"]</code></pre>
                <p>With <code>entrypoint.sh</code></p>
<pre><code>#!/bin/sh -l
set -eux

# Taken from https://github.com/zhxiaogg/cargo-static-build, unsure if all needed or not

# hack, move home to $HOME(/github/home)
ln -s /root/.cargo $HOME/.cargo
ln -s /root/.rustup $HOME/.rustup

# go to the repo root
cd $GITHUB_WORKSPACE
sh -c "$*"
chmod 0777 ./target</code></pre>
                <p>Note that in the above we <code>CD</code> into <code>$GITHUB_WORKSPACE</code>. This is because we are going to adhere to the <a href="https://docs.github.com/en/actions/creating-actions/dockerfile-support-for-github-actions">Dockerfile guide</a> that GitHub published, that we need to adhere to so that our images work with their magic. For local development of this even with the <code>CD</code> command, I run the following commands</p>
                <pre><code># Build the image
docker build -t namespace/tag /path/to/dockerfile

# Once inside your Rust project's root where you would normally cargo deb..
docker run -v $PWD:/volume --rm --env GITHUB_WORKSPACE=. -t namespace/tag cargo deb</code></pre>
        <p>You can see this image's Dockerfile on <a href="https://github.com/ebbflow-io/cargo-deb-amd64-ubuntu/blob/master/Dockerfile">GitHub</a> alongside the other files related to this container. I want to point out that in our testing, the packages built on this image work on both Ubuntu & Debian systems so we never created a Debian specific one (muslrust uses Ubuntu). This compatibility is not the case for Fedora & openSUSE, which I talk about shortly. I'm going to punt on <a href="/blog/vending-linux-2#vendedactions">explaining the final GitHub Actions integration</a> and I'll continue talking about the other images.</p>

            <h4 id="raspbian"><a href="/blog/vending-linux-2#raspbian"><code>.deb</code> for <code>armv7</code> Raspbian (or RaspberryPi OS)</a></h4>
                <img class="blogicon" src="/assets/arm.png" />
                <p>Raspberry Pis are a target of Ebbflows and use the <a href="https://en.wikipedia.org/wiki/ARM_architecture">Arm</a> CPU architecture which differs from nearly all desktop PCs and servers. To target this architecture, we can either actually run the build on a server that has an Arm chip using GitHub Action's <a href="https://docs.github.com/en/actions/hosting-your-own-runners">self hosted runner support</a> or we could emulate the Arm chip using <a href="https://www.qemu.org/">QEMU</a> and Rust's cross-compiling support. The latter seemed easier and doesn't require hardware (although we have a <a href="/guides/raspberrypi">few Raspberry Pis</a> laying around), so we went with that.</p>
                <p>The Rust community comes up big for us again and there are plenty of resources for cross compiling. Namely, the <a href="https://github.com/japaric/rust-cross"><code>rust-cross</code></a> project by GitHub user <a href="https://github.com/japaric">japaric</a>. I created a Debian image and following the guides provided by them and the general internet, I was able to create a <a href="https://github.com/ebbflow-io/cargo-deb-armv7-debian/blob/master/Dockerfile">Dockerfile</a> which pulls in all of the necessary dependencies such as <code>MUSL</code> and <code>cargo-deb</code>. The <code>entrypoint.sh</code> file and local development instructions are the same as the ones above.</p>
                <h4 id="fedora"><a href="/blog/vending-linux-2#fedora"><code>.rpm</code> for Fedora <code>amd64</code></a></h4>
                <p>I just created a Docker image based on the latest Fedora version and started plugging in the packages I needed like <code>MUSL</code>, the Rust toolchain, and <code>rpm-build</code>. You can find the resulting <code>Dockerfile</code> <a href="https://github.com/ebbflow-io/cargo-rpm-amd64-fedora/blob/master/Dockerfile"> on GitHub.</a>
                <h4 id="opensuse"><a href="/blog/vending-linux-2#opensuse"><code>.rpm</code> for OpenSUSE <code>amd64</code></a></h4>
                <p>The Fedora based build really just spits out an <code>.rpm</code> file and I have no code dependent on the actual distro, so ideally I could use this single <code>.rpm</code> across any <code>.rpm</code> friendly distros, of relevance openSUSE. Unfortunately, when I attempted this, openSUSE was unable to run the binaries or install the <code>.rpm</code> built on the Fedora image. I simply created an openSUSE docker image for my needs instead of looking into exactly why this was happening, and you can find that Dockerfile <a href="https://github.com/ebbflow-io/cargo-rpm-amd64-opensuseleap/blob/master/Dockerfile">on GitHub.</a></p>
                <h2 id="imagebuild"><a href="/blog/vending-linux-2#imagebuild"><code>1.3</code> Building & Hosting the Build Images</a></h2>
                <p>We're a few levels deep now. We have our client's code which needs to be built in GitHub Actions, which run on the GitHub Runners, which will use our Docker images. We have Docker images which can produce statically linked packages for the target OSs/CPUs on our desktop, but we haven't plugged that into GitHub Actions in any way, we just have the Dockerfiles ready to go.</p>
                <p>To execute a workflow's step in your own container you can point your Action's definition (we talk about this soon..) to a Dockerfile and GitHub will build the image before using it during your workflow execution. That is fine but if your Docker build is slow, then you will want to have an image already built. GitHub Actions can used public images easily.</p>
                <p>To build and host Docker images, Ebbflow uses <a href="https://hub.docker.com/">Docker Hub</a> which is free for public images. You can find Ebbflow's public images on <a href="https://hub.docker.com/u/ebbflow">its Docker Hub profile</a>. Getting started with Docker Hub is pretty simple, you create and account and create a 'repo' which will point to your GitHub repo, and set up automatic builds. Here is what Ebbflow's build triggers look like which set up builds on new commits or tags to the repo.</p>
                <img class="blogicon" style="max-width: 100%;" src="/assets/dhbuildrules2.png" />
                <p>Once you set this up DockerHub will start building your images and eventually tag them. Its a free service so you cannot really complain, but this might take a minute or two. Once DockerHub is building your images, you are ready to point to them in your Actions. Finally!</p>
                <h2 id="vendedactions"><a href="/blog/vending-linux-2#vendedactions"><code>2.0</code> Creating Github Actions</a></h2>
                <p>To use the Docker images we've created we must create an 'Action' for them and will <a href="https://docs.github.com/en/actions/creating-actions/creating-a-docker-container-action#creating-a-dockerfile">follow this guide</a> to do so. An Action is just a <code>.yaml</code> definition, and Actions do not need to be published to the Marketplace for you to use them. I want to give a thank you to GitHub user <a href="https://github.com/zhxiaogg">zhxiaogg</a> for developing the <a href="https://github.com/marketplace/actions/cargo-static-build">cargo-static-build</a> Action which acted as the template for all of our actions. So given our built and hosted images, we can reference them pretty simply, here is an example for our Arm build that you can also find <a href="https://github.com/ebbflow-io/cargo-deb-armv7-debian/blob/master/action.yml">on GitHub</a>.
                <p>Besides some basic information like a description, the interesting parts are in the <code>cmd</code> section and <code>runs</code> section. The <code>runs</code> part just points to our DockerHub image that was tagged for us, and passes the input value <code>cmd</code> to the entrypoint script. For the <code>cmd</code> section, we are just defining the default command to use for this action. For this to make sense, let's look at how we would reference this Action in an example workflow:</p>
<pre><code>on: [push]

name: Continuous Integration

jobs:
  raspbianbuild:
    runs-on: ubuntu-latest
    name: Armv7Deb
    steps:
    - uses: actions/checkout@v2
    - name: BuildDeb
      id: debbuild
      uses: ebbflow-io/cargo-deb-armv7-debian@1.0
    - name: Upload Deb Artifact
      uses: actions/upload-artifact@v2
      with:
        name: armv7deb
        path: ./target/armv7-unknown-linux-musleabihf/debian/*
</code></pre>
                <p>The integration is easy - we just point to the <a href="https://github.com/ebbflow-io/cargo-deb-armv7-debian">Arm Action repository</a> and specify the <code>1.0</code> version. Note how we are not providing a command because that was handled by the <code>default</code> entry in our Action definition above. After the build, we use the <a href="https://github.com/marketplace/actions/upload-a-build-artifact">artifact upload</a> Action to get access to your built package later. The <code>action.yml</code> files for our other actions are almost identical except for the image and the command.</p>
<h2 id="workflow"><a href="/blog/vending-linux-2#workflow"><code>3.0</code> Final GitHub Action Workflow</a></h2>
<p>The Ebbflow Client's workflow file is a few hundred lines long, so instead of pasting it here I encourage you to check it out <a href="https://github.com/ebbflow-io/ebbflow/blob/master/.github/workflows/continuous-integration.yml">on GitHub</a>.
<p>In this workflow we run a 'quickcheck' job to verify that the build succeeds and we spit put the version of our project which is taken from our <code>Cargo.toml</code> file. The <code>raspbianbuild</code> step waits for this to finish first, then begins the build which uses our Arm build Action. Once that is complete. we will create a Draft of our Release and upload our built artifact(s) to it.</p>
<p>This process is pretty great, after a <code>git push</code> GitHub Actions will build everything and upload it to a 'release' as a Draft, and if I want to release a version, I just fill out the title and description, and all of the assets are there. Check out one of our <a href="https://github.com/ebbflow-io/ebbflow/releases/tag/1.0.1">releases</a> to see all the assets - those were all placed there automatically.</p>
<h2 id="wrapup"><a href="/blog/vending-linux-2#wrapup"><code>4.0</code> Wrapup: All of our Public Resources</a></h2>
<p>Ebbflow has also taken all of the Actions that were necessary for us to build our client and vended them on the Actions Marketplace. Also our build images can be found on <a href="https://hub.docker.com/u/ebbflow/">DockerHub</a>. Here are the direct links for the resources we've created:</p>
<div style="overflow: auto; max-width: 90%; font-size: 0.95em;">
  <table>
    <tr>
      <td>Format</td>
      <td>Build Distro</td>
      <td>CPU arch.</td>
      <td>Code</td>
      <td>Action</td>
      <td>DockerHub</td>
    </tr>
    <tr>
      <td><code>.deb</code></td>
      <td>Ubuntu</td>
      <td><code>amd64</code></td>
      <td>
        <a href="https://github.com/ebbflow-io/cargo-deb-amd64-ubuntu">repo</a>
      </td>
      <td>
        <a href="https://github.com/marketplace/actions/rust-cargo-deb-package-build-amd64-ubuntu">action</a>
      </td>
      <td>
        <a href="https://hub.docker.com/r/ebbflow/cargo-deb-amd64-ubuntu">image</a>
      </td>
    </tr>
    <tr>
      <td><code>.deb</code></td>
      <td>Debian</td>
      <td><code>armv7</code></td>
      <td>
        <a href="https://github.com/ebbflow-io/cargo-deb-armv7-debian">repo</a>
      </td>
      <td>
        <a href="https://github.com/marketplace/actions/rust-cargo-deb-package-build-armv7-debian">action</a>
      </td>
      <td>
        <a href="https://hub.docker.com/r/ebbflow/cargo-deb-armv7-debian">image</a>
      </td>
    </tr>
    <tr>
      <td><code>.rpm</code></td>
      <td>Fedora</td>
      <td><code>amd64</code></td>
      <td>
        <a href="https://github.com/ebbflow-io/cargo-rpm-amd64-fedora">repo</a>
      </td>
      <td>
        <a href="https://github.com/marketplace/actions/rust-cargo-rpm-package-build-amd64-fedora">action</a>
      </td>
      <td>
        <a href="https://hub.docker.com/r/ebbflow/cargo-rpm-amd64-fedora">image</a>
      </td>
    </tr>
    <tr>
      <td><code>.rpm</code></td>
      <td>openSUSE Leap</td>
      <td><code>amd64</code></td>
      <td>
        <a href="https://github.com/ebbflow-io/cargo-rpm-amd64-opensuseleap">repo</a>
      </td>
      <td>
        <a href="https://github.com/marketplace/actions/rust-cargo-rpm-package-build-amd64-opensuseleap">action</a>
      </td>
      <td>
        <a href="https://hub.docker.com/r/ebbflow/cargo-rpm-amd64-opensuseleap">image</a>
      </td>
    </tr>
  </table>
</div>
<p>I hope you found this useful! All in all, the Docker image creation and GitHub integration took me around 5 days to fully flesh out and get working in case you were curious on the effort it took. With this guide, I hope it takes you less time! The next blog post will talk about how these packages actually end up in the hands of our customers. These will be posted to <a href="https://www.reddit.com/r/rust/">/r/rust</a>, Hacker News, or feel free to send an email to <a href="mailto:info@ebbflow.io">info@ebbflow.io</a> with subject "blog subscribe" (or something similar) to have blog posts be emailed to you, or check back at <a href="https://ebbflow.io/blog">ebbflow.io/blog</a>.</p>
<p><i>Thanks for taking the time to read this! If you'd like to check out Ebbflow you can use the free trial without providing a credit card! Simply <a href="/create_account">create an account</a> and get started. It <a href="/quickstart#endpointguide">takes six minutes</a> to register, install the client, and host your website!</i></p>
            </div>
        </article>
    </div>
</div>