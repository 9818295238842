import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlogComponent } from './blog/blog.component';
import { IndexComponent } from './index/index.component';
import { Linux2Component } from './blog/linux2/linux2.component';
import { LinuxComponent } from './blog/linux/linux.component';
import { WinComponent } from './blog/win/win.component';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
  ],
  declarations: [
    AppComponent,
    BlogComponent,
    IndexComponent,
    Linux2Component,
    LinuxComponent,
    WinComponent
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
