<div class="contentleftmargin">
    <div class="pod podtop">
<article class="blogarticle">
   <div style="margin: auto;max-width: 800px;text-align: center; margin-top: 60px;">
   <a href="/blog/vending-win"><h2 style="margin-bottom: 16px">Packaging & Vending Production Rust Software For Windows</h2></a><h3 style="margin-top: 16px">A look into vending a Rust project for various OSes and CPU architectures.</h3>
    <p style="text-align: center;">Ryan Gorup - Founder</p>
    </div>
    <div class="blogtext">
        <p>This post describes how Ebbflow vends its <a href="https://github.com/ebbflow-io/ebbflow">client</a> to Windows users which is written in Rust, describing the tools used to build and ultimately deliver the program to users.</p>
        <h3>Ebbflow Client 101</h3>
         <p>Ebbflow's job is to route user traffic to your web server application (or SSH Daemon) on your servers. The central Ebbflow service proxies data between user connections (e.g. browser, SSH client) and the client. The client then proxies the data to your web server or local SSH daemon. Users of Ebbflow install the client on machines that will host endpoints, be SSH-ed to, or both at the same time (which is very useful). The following diagram shows this visually.</p>
        <img id="clientdg" src="/assets/clientdiagram.png" />
        <p><small><i>Quick note: The client initiates the connection to the central Ebbflow service via an outbound TLS connection, which makes the client extremely firewall friendly. You may completely block all inbound connections but still host websites or be SSH-ed to! Also, this innovation allows the servers to be located in any network, and even change networks without any configuration at all. Ebbflow just receives connections from the general internet, and after authentication and authorization, will allow the server to host the endpoint or be SSH-ed to. Neat!</i></small></p>
        <p>The client has two parts, the CLI and the background daemon. Both of these programs are 100% Rust, 100% <code>async</code>, and 100% 'safe', <i>and</i> statically linked - the dream of any Rust developer! The <a href="https://github.com/ebbflow-io/ebbflow/blob/master/src/ebbflow.rs">CLI</a> is an executable tool named <code>ebbflow</code> that is used to tell the background to host new endpoints, disable or re-enable endpoints or the SSH proxy, and configure other settings.</p>
        <p>The background daemon is the second piece to this puzzle, and is the workhorse and is responsible for actually transferring bytes between the central Ebbflow servers and your local web server or SSH daemon. This daemon is just a long-running background executable named <a href="https://github.com/ebbflow-io/ebbflow/blob/master/src/ebbflowd.rs"><code>ebbflowd</code></a>.</p>
        <h2>Windows</h2>
        <img class="blogicon" src="/assets/windows.png" />
        <p>Supporting Windows as a first-class server OS is very important to Ebbflow. Taking the two executables & re-compiling them on Windows was simple thanks to Rust's abstractions over the underlying OS and numerous community projects. First things first, the background daemon needs to be changed to work in a Windows world.</p>
        <h3>Background Daemon / Windows Service</h3>
        <p>The <code>ebbflow</code> CLI is very simple and is portable in the sense that you could execute the binary on a windows machine without installing it and it will do its job correctly, but <code>ebbflowd</code> is another story. To execute a background program in Windows one uses the <a href="https://docs.microsoft.com/en-us/dotnet/framework/windows-services/introduction-to-windows-service-applications">Windows Service</a> framework. This framework is needed as the <code>ebbflowd</code> program needs to run when there are no interactive users, start when the system starts, and be watched and restarted in the event of a crash (which has not happened due to Rust's inherit safety and never <code>unwrap</code>ing).</p>
        <p>A sticking point of using the Windows Service framework is that your program needs to implement a specific <a href="https://docs.microsoft.com/en-us/windows/win32/services/service-programs">interface</a>, namely it must provide an entry point, handle signals, and register itself. To help, the <a href="https://crates.io/crates/windows-service"><code>windows-service</code></a> Rust crate (provided by <a href="https://github.com/faern">Linus Färnstrand</a>) makes this very simple.</p>
        <p>This <code>windows-service</code> crate provides a macro which requires that you implement just a few functions that are effectively <code>main</code> functions. The Ebbflow client uses <code>#[cfg(windows)]</code> to conditionally include all of the <a href="https://github.com/ebbflow-io/ebbflow/blob/master/src/ebbflowd.rs#L25-L142">necessary code</a> to implement these functions and therefore the Windows Service interface itself! The <a href="https://crates.io/crates/winlog">winlog</a> crate was also helpful for taking <a href="https://crates.io/crates/log">log events</a> and adding them to the <a href="https://docs.microsoft.com/en-us/windows/win32/wes/windows-event-log">Windows Event Log</a> system.</p>
        <h3>Going Static</h3>
        <p>Rust will dynamically link against the OS's <code>libc</code> implementation when the standard library is used, which is the case for almost all substantial Rust applications. On Windows, this means Rust will compile programs and link them to the system's 'MSVC' (MicroSoft Visual C++) libc implementation. Interestingly, the default Windows 10 image does NOT include this library! This results in Rust code breaking when executed on a new system, something that has come up in testing, but may not come up in development as MSVC would likely get installed at one point or another when setting up a Windows machine for code development.</p>
        <p>Statically linking Rust code is simple. To bring along MSVC with your compiled Rust program, you <a href="https://github.com/rust-lang/rfcs/blob/master/text/1721-crt-static.md">instruct</a> the compiler to statically link MSVC. In my case I <a href="https://github.com/ebbflow-io/ebbflow/blob/master/.github/workflows/continuous-integration.yml#L89-L90">set just one environment variable</a> (<code>RUSTFLAGS='-C target-feature=+crt-static'</code>) and it simply worked, but you can add a <a href="https://stackoverflow.com/a/44387312">config file entry or pass a flag</a> to compilation as well.</p>
        <p>Besides the <code>std</code> library, Rust code may link to other OS libraries most often OpenSSL or libsodium for crypto. The Ebbflow client avoids this by using <a href="https://crates.io/crates/rustls"><code>rustls</code></a> which uses <a href="https://crates.io/crates/ring">ring</a> under the hood. Rustls is an ergonomic TLS library for Rust. It is <a href="https://jbp.io/2019/07/01/rustls-vs-openssl-performance.html">highly performant</a> even compared to OpenSSL and recently underwent a 3rd party <a href="https://github.com/ctz/rustls/blob/main/audit/TLS-01-report.pdf">security audit</a> which showed no flaws. When you invest in the Rust ecosystem and use Rust-written libraries, you are rewarded with the ability to statically link which is a desirable situation.</p>
        <h3>Building the <code>.msi</code> using Wix</h3>
        <p>Windows uses 'installers' in the form of <code>.msi</code> packages to handle which packages are installed on a system. These installers tell Windows what files/executables go where and contain logic to handle upgrades, uninstallations, and configuration management.</p>
        <p>Ebbflow uses Wix which is a tool that takes an <code>.xml</code> configuration file and generates <code>.msi</code> packages. Conveniently, a plug-in to Rust's <code>cargo</code> build tool named <a href="https://crates.io/crates/cargo-wix"><code>cargo-wix</code></a> exists. This tool can generate a skeleton Wix <code>xml</code> file which we then modified to suit our needs. Under the hood, executing <code>cargo wix</code> will compile your program in <code>--release</code>, then package up everything into your <code>.msi</code> using <a href="https://wixtoolset.org/documentation/manual/v3/overview/candle.html">candle.exe</a> to compile your Wix config and <a href="https://wixtoolset.org/documentation/manual/v3/overview/light.html">light.exe</a> to link it.</p>
        <p>Generating and tinkering with the <a href="https://github.com/ebbflow-io/ebbflow/blob/master/wix/main.wxs">client's Wix file</a> took some time. There are numerous StackOverflow q/a's and other resources to help with writing the Wix file which was helpful, but I found that vending a Windows Service in an installer is more of an edge case. Also, configuring the UI components of the installer is tricky and I never found a great reference implementation or other documentation.</p>
        <h3>Automated Builds using GitHub Actions</h3>
        <p>Up to this point, I've produced a statically linked Windows program and packaged it into an installer. For various reasons such as build reproducability & transparency, and hosting the built <code>.msi</code> for free, Ebbflow uses <a href="https://github.com/features/actions">GitHub Actions</a> to build and package up the client. You can see the client's <a href="https://github.com/ebbflow-io/ebbflow/blob/master/.github/workflows/continuous-integration.yml#L86-L106">Windows build configuration on GitHub</a>, and here is a snippet:</p>

        <pre>
            <code>
# See https://github.com/ebbflow-io/ebbflow/blob/master/.github/workflows/continuous-integration.yml
# for a real world example
windowsbuild:
  runs-on: windows-latest
  env:
    RUSTFLAGS: '-C target-feature=+crt-static'
  name: Windows
  steps:
  - uses: actions/checkout@v2
  - uses: actions-rs/toolchain@v1
    with:
      toolchain: stable
  - uses: actions-rs/install@v0.1
    with:
      crate: cargo-wix
      version: latest
  - run: cargo wix -v --nocapture -o .
  - name: Upload MSI Artifact
    uses: actions/upload-artifact@v2
    with:
      name: windows
      path: ./*.msi                
            </code>
        </pre>
        <p>GitHub provides a Windows image that has <a href="https://github.com/actions/virtual-environments/blob/master/images/win/Windows2019-Readme.md">various build tools included</a>, importantly Wix. Getting Windows builds set up on GitHub Actions was pretty simple and I had a great experience with the service. </p>
        <h3>Distribution to Users</h3>
        <p>After writing the code, making an installer and testing that out, the next step is to figure out how to get this into our customers hands. To solve this, the first thought is to just distribute the <code>.msi</code> file itself and notify customers of upgrades. This generally works and the installer can handle upgrades and uninstallations. However, there are drawbacks. The major one is that any future updates will require the customer to download the new <code>.msi</code>, which requires some custom process. Windows will flag any software that is unsigned and all applications must be signed using certificates derived from trusted certificate authorities. This is fine and secure but to have our <code>.msi</code> work without warnings you must purchase a code signing certificate/key from someone like Digicert or the like which can easily run into the hundreds of dollars. Alternatively you can create your own CA & signing key/certificate, self-sign the <code>msi</code>, then have all customers add your CA to their trusted store. This does not seem too common from my understanding.</p>
        <p>These two drawbacks are pretty severe, which is what leads us to our chosen solutions, Chocolatey and Winget.</p>
        <h4>Chocolatey</h4>
      <img class="blogicon" src="/assets/choco.svg" />
        <p><a href="https://chocolatey.org/">Chocolatey</a> is a 3rd party package manager and you can use it to install, update, remove, and/or search for packages. It works great for personal use and will feel very familiar to any Linux users. Chocolatey also provides a multi-computer management solution to enterprise customers so sysadmins could distribute and maintain many Windows computers from afar.</p>
        <p>Importantly, Chocolatey packages do not require code-signing and will not trigger any warnings when installed. Adding package to the Chocolatey package store is <a href="https://chocolatey.org/docs/CreatePackagesQuickStart#prerequisites">decently simple</a>. Chocolatey deals in <code>.nupkg</code>-es, which are easily created with the Chocolatey CLI. Execute <code>choco new</code> to generate a base set of files. After modifying them, you package up everything into the <code>.nupkg</code> container using <code>choco pack</code>. Ebbflow's chocolatey repo can be found <a href="https://github.com/ebbflow-io/chocolatey-ebbflow">on GitHub</a>. Be sure to remove configuration entries or files that you don't need. And for referencing the actual installer for download, we just use the GitHub Release artifact that we post, all thanks to our earlier decision to build on GitHub.</p>
        <p>Once you submit your package to Chocolatey, it goes through two automated steps of validation and it will spit out some advice that can be required and block your package from being released or it will spit out recommended changes like adding specific links or documentation. If these pass, the package enters 'moderation', and an actual human will review the package before it is released. This can take days to weeks, and is a drawback. Chocolatey does have Business plans, but to my knowledge these do not come with quicker package moderation.</p>
        <p>Further, any new versions of a package after the initial moderation will need to be human-moderated. Your package can become 'Trusted' to allow new versions to bypass the human moderation, but this takes several complaint/issue-free versions of your application to be released.</p>
        <h4>Winget</h4>
        <p>Windows is working on a 1st party package manager named <a href="https://github.com/microsoft/winget-cli"><code>winget</code></a>. Its exciting and adding your program to the <a href="https://github.com/microsoft/winget-pkgs">community repo</a> is simple and will feel familiar if you've worked with MacOS's Homebrew system. You must <a href="https://github.com/microsoft/winget-pkgs#authoring-a-manifest">author a 'manifest'</a> which points to your <code>.msi</code> and includes things like a description and a <code>SHA256</code> hash. You can view Ebbflow's Winget configuration <a href="https://github.com/microsoft/winget-pkgs/tree/master/manifests/Ebbflow/Ebbflow">on GitHub</a>.</p>
        <p>Like Chocolatey, you do not need to code-sign your package and will not trigger any warnings on installation. Also like Chocolatey, there is a human moderation/approval process that is needed for each package and subsequent version. I believe this will change in the future when <code>winget</code> <a href="https://github.com/microsoft/winget-cli#sources">adds support for other sources</a>. At that point, I assume you would have full control of your package and users will just need to add your Source to their source list. <code>winget</code> is promising and I'm looking forward to seeing it develop and march towards release in 2021.</p>
        <h2>Conclusion</h2>
         <p>The Rust language and 1st party tools facilitate a quick and smooth transition from code running only using <code>cargo run</code> to a fully built and distributed program. The static linking support is simple. The <code>cargo</code> tool is a game changer; it is used for dependency management which is a major headache for most languages. <a href="https://github.com/rust-lang/cargo/wiki/Third-party-cargo-subcommands">Cargo subcommands</a> are a joy to use and made integration with Wix dead simple. The <a href="https://crates.io/crates/cargo-wix"><code>cargo wix</code></a> subcommand allows developers to stay within the Rust ecosystem and still build and compile their program idiomatically through <code>cargo</code>.</p>
         <p>Vending a package to Windows is overall a nice experience. The tooling works well and there is a good amount of online resources available such as prior-art so to speak on GitHub and other sources. Also, general documentation and StackOverflow are widely available. The biggest sticking points were anything with Wix, working with the 'Service' framework, and waiting for Chocolatey and winget packages to be approved. Non-Windows developers will find it easy to get started building and vending Rust code thanks to the language's built in support and through the numerous helpful community provided projects.</p>

         <p><i>Thanks for taking the time to read this! If you'd like to check out Ebbflow you can use the free trial without providing a credit card! Simply <a href="/create_account">create an account</a> and get started. It <a href="/quickstart#endpointguide">takes six minutes</a> to register, install the client, and host your website!</i></p>
   
    </div>
</article>
    </div>
</div>