import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-linux2',
  templateUrl: './linux2.component.html',
  styleUrls: ['./linux2.component.css']
})
export class Linux2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
