import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlogComponent } from './blog/blog.component';
import { LinuxComponent } from './blog/linux/linux.component';
import { Linux2Component } from './blog/linux2/linux2.component';
import { WinComponent } from './blog/win/win.component';
import { IndexComponent } from './index/index.component';

const routes: Routes = [
    { path: '', component: IndexComponent },
    { path: 'blog', children: [
        {
          path: '',
          component: BlogComponent
        },
        {
          path: 'vending-win',
          component: WinComponent
        },
        {
          path: 'vending-linux-1',
          component: LinuxComponent
        },
        {
          path: 'vending-linux-2',
          component: Linux2Component
        }
      ]
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
