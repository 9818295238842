<meta name="viewport" content="width=device-width, initial-scale=1.0">
<link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,900&amp;display=swap" rel="stylesheet">
<meta charset="utf-8">
<link rel="shortcut icon" href="/favicon.ico">
<link rel="icon" sizes="16x16 32x32 64x64" href="/assets/favi/favicon.ico">
<link rel="icon" type="image/png" sizes="196x196" href="/assets/favi/favicon-192.png">
<link rel="icon" type="image/png" sizes="160x160" href="/assets/favi/favicon-160.png">
<link rel="icon" type="image/png" sizes="96x96" href="/assets/favi/favicon-96.png">
<link rel="icon" type="image/png" sizes="64x64" href="/assets/favi/favicon-64.png">
<link rel="icon" type="image/png" sizes="32x32" href="/assets/favi/favicon-32.png">
<link rel="icon" type="image/png" sizes="16x16" href="/assets/favi/favicon-16.png">
<link rel="apple-touch-icon" href="/assets/favi/favicon-57.png">
<link rel="apple-touch-icon" sizes="114x114" href="/assets/favi/favicon-114.png">
<link rel="apple-touch-icon" sizes="72x72" href="/assets/favi/favicon-72.png">
<link rel="apple-touch-icon" sizes="144x144" href="/assets/favi/favicon-144.png">
<link rel="apple-touch-icon" sizes="60x60" href="/assets/favi/favicon-60.png">
<link rel="apple-touch-icon" sizes="120x120" href="/assets/favi/favicon-120.png">
<link rel="apple-touch-icon" sizes="76x76" href="/assets/favi/favicon-76.png">
<link rel="apple-touch-icon" sizes="152x152" href="/assets/favi/favicon-152.png">
<link rel="apple-touch-icon" sizes="180x180" href="/assets/favi/favicon-180.png">
<meta name="msapplication-TileColor" content="#FFFFFF">
<meta name="msapplication-TileImage" content="/assets/favi/favicon-144.png">
<meta name="msapplication-config" content="/assets/favi/browserconfig.xml">
<meta name="twitter:card" content="summary">
<meta name="twitter:title" content="Ebbflow">
<meta name="twitter:description" content="A Multi-Cloud Load Balancer and SSH Proxy">
<meta name="twitter:site" content="@ebbflow_io">
<meta name="twitter:image" content="https://ebbflow.io/assets/justeblack.png">
<meta name="twitter:creator" content="@ebbflow_io">
<title>ebbflow | Load Balancing For Any Cloud Anywhere</title>
<div id="container">
  <div id="body">
<div style="display: flex; padding: 7px 4px; justify-content: center; align-items: center; background-color: #79fad5; flex-wrap: wrap;">
    <div style="text-align: center; margin: 0px 5px;">😓 Ebbflow has closed down! You can still find blog posts and general information on this site</div>
</div>
<nav id="outernav">
<div id="nav" style="min-height: 30px;">
    <div class="navleft">
        <div class="navitem first">
            <a href="/">
                <img style="height: 26px; display: inline-block;" src="/assets/logo_blue.svg" />
            </a>
        </div>
    </div>
    <div class="navright" id="rightmenu">
      <div class="navitem">
          <a routerLink="/blog" routerLinkActive="active">Blog</a>
      </div>
    </div>
</div>
</nav>
<router-outlet></router-outlet>
  </div>

<div id="footer">
    <footer>
        <div class="contentleftmargin">
            <div id="footercontent">

              <div id="bottomwrapper">
              <div class="bottomt">
                  <h4>Info</h4>
                  <p class="white">Contact <code>ryan at ebbflow dot io</code></p>
                  <p class="white"><a href="https://github.com/ebbflow-io" target="_blank">Github</a></p>
                  <p class="white"><a href="/blog">Blog</a></p>
              </div>
              </div>
          </div>
        </div>
    </footer>
</div>
</div>
